/*
    MidBody.jsx: 

    MidBody -- BoxScore x4

    Nov.24,2021 : id を p# -> u# に変更

*/
import React, { useContext } from "react";
import { HStack } from "@chakra-ui/react"
import BoxScore from "./BoxScore"

import { PartyContext } from '../pages/Score'

export default function MidBody(props) {
    // 基本 Box
    const { partyData } = useContext(PartyContext)

    return (
        <HStack >
            <BoxScore key="s1" id="u1" uid="1" name={partyData.player1_name} />
            <BoxScore key="s2" id="u2" uid="2" name={partyData.player2_name} />
            <BoxScore key="s3" id="u3" uid="3" name={partyData.player3_name} />
            <BoxScore key="s4" id="u4" uid="4" name={partyData.player4_name} />
        </HStack>
    )
}
