/*
   Total Score 表示
    props.uid に対応した scorePx に stroke を設定する．

    Input: PartyContext, uid
    Output: Display Only / update TotalScore

    Jul.18,2023 : plusMinusMode 対応
    Jul.14,2023 : fontSize Up して，delta 表示を削除
    Apr.18,2021 : Stroke と st_delta を上下から左右に並べることでコンパクト化した．
    Apr.16,2021 : 最初のHole で score data が無い時の処理を追加
*/
import React, { useContext } from 'react'
import { Box, Center, HStack, Text } from '@chakra-ui/react'

import { PartyContext } from '../pages/Score'
import config from '../pages/const'

export default function BoxTotal(props) {
    // 基本 Box
    const {
        curHole,
        inputMode,
        inputUid,
        plusMinusMode,
        colorZone1,
        colorZone2,
        colorZone3,
        scoreP1,
        scoreP2,
        scoreP3,
        scoreP4,
        //setScoreP1, setScoreP2, setScoreP3, setScoreP4,
        //totalScore,
        shotScores,
    } = useContext(PartyContext)

    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2

    const scorePx = eval('scoreP' + props.uid)
    //console.log("TTL: ", props.uid, shotScores.filter((v) => v.p_id == props.uid))

    // curHole 以外の shotScore を scores とする．
    const scores =
        shotScores.filter((v) => v.p_id == props.uid) !== undefined
            ? shotScores.filter((v) => v.p_id == props.uid && v.hole_order !== curHole)
            : null

    // score の ttl と，現在表示の stroke を足して ttlStroke とする．
    const ttlStroke = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.stroke
          }, 0) + scorePx.stroke
        : scorePx.stroke
    const ttlStrokeDelta = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.st_delta
          }, 0) + scorePx.st_delta
        : scorePx.st_delta
    const ttlPutt = scores
        ? scores.reduce((sum, sc) => {
              return sum + sc.putt
          }, 0) + scorePx.putt
        : scorePx.putt

    return (
        <>
            {/* Total Stroke */}
            <Box textAlign="center" py={0} my={0} borderRadius={5} bg="gray.200">
                <Center>
                    <Text
                        fontSize={38}
                        fontWeight="bold"
                        px={0}
                        mx={1}
                        borderRadius={5}
                        color="gray.600"
                        bg="gray.200"
                    >
                        {plusMinusMode ? (ttlStrokeDelta > 0 && '+') + ttlStrokeDelta : ttlStroke}
                    </Text>
                </Center>
            </Box>
            <HStack>
                <Center fontSize={18} h={18} w="30%">
                    Pt
                </Center>
                {/* Total Putt */}
                <Center
                    fontSize={30}
                    fontWeight="bold"
                    w="70%"
                    py={0}
                    borderRadius={5}
                    color="gray.600"
                    bg="gray.200"
                >
                    {ttlPutt}
                </Center>
            </HStack>
        </>
    )
}
