/*

Presen.js : Presen Mode の Button とその処理

Mar.18,2024 : ODDT 使用してないので削除
Feb.24,2024 : presen の表示見直し，Putt計を追加
Feb.22,2024 : table 条件表示部の冗長code 見直し
Feb.21,2024 : 自己ベスト更新 BrkR を追加
Oct.17,2023 : submitResult(team_win を round db へ wt) を追加
Oct.12,2023 : BoxTHRU と Presen を Presen に統合した
Oct.08,2023 : NetT の表示で Grs を表示するように変更
Nov.11,2022 : name="" は非表示にする
Sep.27,2022 : NetT を ODDT flag で表示していたが，NetT flag に修正．
Jul.21,2022 : sessionStorage に保存してある, TodaysRules に基づいて計算
              NetT を表示(ODDT も表示できるが同じものを表示している)
Jul.19,2022 : 表示変更: BiP -> BKiP
Dec.14,2021 : TTL の描画が追い付かない．最後の手段: updateVal で強制的に rendering を Update．
Dec.12,2021 : page に昇格
Dec.12,2021 : Table 調整
Dec.08,2021 : Round.js に新規追加

*/
import React, { useState, useEffect, useRef } from 'react'
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Stack,
    VStack,
    Spacer,
    IconButton,
    Text,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Tooltip,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import { CheckIcon } from '@chakra-ui/icons'

// WebSocket
import ReconnectingWebSocket from 'reconnecting-websocket'

import calcPoint from './calcPoint'
import config from './const'

const WS_ORIGIN = process.env.REACT_APP_BESVR_WS_ORIGIN
const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

function DrawerDispQuickSettings({ checkedRules, setCheckedRules, masterMode, setMasterMode }) {
    //
    // 個人Tab の QuickSettings
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const todaysRules = sessionStorage.getItem('TodaysRules')
        ? JSON.parse(sessionStorage.getItem('TodaysRules'))
        : checkedRules

    function saveRules(rules) {
        for (var rule in todaysRules) {
            // todaysRules に無いものは cut する
            if (todaysRules[rule] == false) {
                rules[rule] = false
            }
        }
        setCheckedRules(rules)
    }

    return (
        <>
            {masterMode ? (
                <Box ref={btnRef} color="orange" onClick={onOpen}>
                    Cat
                </Box>
            ) : (
                <Box ref={btnRef} color="white" onClick={onOpen}>
                    Cat
                </Box>
            )}
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Display Settings</DrawerHeader>

                        <DrawerBody>
                            <Stack spacing={1} direction="column">
                                <Text fontSize="sm" color="gray.600">
                                    CheckBox の Click で表示します．
                                </Text>
                                <Checkbox
                                    defaultIsChecked
                                    value="masterMode"
                                    isChecked={masterMode}
                                    onChange={(e) => setMasterMode(e.target.checked)}
                                >
                                    発信者モード
                                </Checkbox>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="red.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            setCheckedRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: false,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Start: 表示Reset</Text>
                                </Flex>

                                <Text fontSize="lg" color="green.600">
                                    表示項目
                                </Text>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: true,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>個人成績</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: true,
                                                BrkR: true,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Brk Rec/自己ベスト</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: true,
                                                GrsT: true,
                                                Rslt: false,
                                                BrkR: true,
                                                AL: true,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>AL/Tm Grs/Tm Net/Brk Rec</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: true,
                                                St6: true,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: false,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Rule 6</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: true,
                                                NPt: true,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: true,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: false,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>DP/NP/1-On</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: true,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: true,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: false,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            //setCheckedRules
                                            onClose()
                                        }}
                                    />
                                    <Text>Hole x Hole</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: true,
                                                BiP: true,
                                                BDy: true,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: false,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: false,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>PoP/BkP/Bdy</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: false,
                                                BiP: false,
                                                BDy: false,
                                                DPt: false,
                                                NPt: false,
                                                Ho3: false,
                                                StD: false,
                                                PtD: false,
                                                On1: false,
                                                HxH: false,
                                                Pt6: false,
                                                St6: false,
                                                OLt: true,
                                                HxHH: false,
                                                NetT: false,
                                                GrsT: false,
                                                Rslt: true,
                                                BrkR: false,
                                                AL: false,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>OL</Text>
                                </Flex>
                                <Flex>
                                    <IconButton
                                        h={6}
                                        w={12}
                                        ref={btnRef}
                                        color="gray.700"
                                        icon={<CheckIcon />}
                                        onClick={(e) => {
                                            saveRules({
                                                ...checkedRules,
                                                PoP: true,
                                                BiP: true,
                                                BDy: true,
                                                DPt: true,
                                                NPt: true,
                                                Ho3: true,
                                                StD: true,
                                                PtD: true,
                                                On1: true,
                                                HxH: true,
                                                Pt6: true,
                                                St6: true,
                                                OLt: true,
                                                HxHH: true,
                                                NetT: true,
                                                GrsT: true,
                                                Rslt: false,
                                                BrkR: true,
                                                AL: true,
                                            })
                                            onClose()
                                        }}
                                    />
                                    <Text>Total</Text>
                                </Flex>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}

function generateUuid() {
    // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
    // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')
    for (let i = 0, len = chars.length; i < len; i++) {
        switch (chars[i]) {
            case 'x':
                chars[i] = Math.floor(Math.random() * 16).toString(16)
                break
            case 'y':
                chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16)
                break
        }
    }
    return chars.join('')
}

export default function Presen(props) {
    //
    //  WebSocket Manager
    //
    const location = useLocation()

    const presenMode = location.state.presenMode
    const playMode = location.state.playMode
    const members = location.state.members
    const rid = location.state.rid
    const pnum = location.state.pnum
    const parData = location.state.parData
    const teamName = location.state.teamName
    const teamMemberOrder = location.state.teamMemberOrder
    const initrules = location.state.checkedRules
    //const setCheckedRules = location.state.setCheckedRules

    const socketRef = React.useRef()
    const myUuidRef = React.useRef('u' + generateUuid())

    const [updateVal, setUpdateVal] = useState(0) // Force Rendering Update Dummy State

    const origData = location.state.baseData
    const [scores, setScores] = useState(location.state.scores)
    const [teamScores, setTeamScores] = useState(location.state.teamScores)
    const [sumScores, setSumScores] = useState(location.state.sumScores)
    const [sumTeamScores, setSumTeamScores] = useState(location.state.sumTeamScores)
    const [masterMode, setMasterMode] = useState(false)

    const [checkedRules, setCheckedRules] = useState(
        presenMode
            ? {
                  ...initrules,
                  PoP: false,
                  BiP: false,
                  BDy: false,
                  DPt: false,
                  NPt: false,
                  Ho3: false,
                  StD: false,
                  PtD: false,
                  On1: false,
                  HxH: false,
                  Pt6: false,
                  St6: false,
                  OLt: false,
                  HxHH: false,
                  NetT: false,
                  GrsT: false,
                  Rslt: false,
                  BrkR: false,
                  AL: false,
              }
            : initrules
    )
    //const loadCheck = useRef(false)   // 2回ロードされないようにする flag

    useEffect(() => {
        if (presenMode == true) {
            console.log('>>>>>>>>>>>>>>>>>>>>>>>> Connecting...', myUuidRef.current)

            socketRef.current = new ReconnectingWebSocket(
                WS_ORIGIN + 'rr' + rid + '/pxx/' + myUuidRef.current
            )

            socketRef.current.onopen = (e) => {
                console.log('---------- CONNECTED ----')
                /*
                var msg = { type: "showup", uid: myUuidRef.current, place: "10" }
                console.log(">>>>>>>> SEND: SHOWUP(10): ", msg)
                socketRef.current.send(JSON.stringify(msg))
                */
            }

            socketRef.current.onerror = (error) => {
                console.log('SOCKET ERROR: ', error)
                //setIsOpenAlt(true)
            }

            socketRef.current.onmessage = (e) => {
                var msg = JSON.parse(e.data)
                //var { type, uid, rules } = JSON.parse(e.data)
                console.log('<<<<<<<< WS RECEIVE MSG : ', msg)

                // msg format
                // {type: , uid: , rules: {rulea: xx, ruleb: xx } }
                switch (msg.type) {
                    case 'updaterule':
                        //if (msg.uid != myUuidRef.current) {
                        if (masterMode == false && msg.uid != myUuidRef.current) {
                            console.log('<<< Rcv Update Rule...', msg.rules)
                            setCheckedRules(msg.rules)
                        } else {
                            console.log('<<< Rcv My Update Rule... Ignored')
                        }
                        break
                    default:
                        console.log('<<< Rcv Err Msg...', msg.type)
                        break
                }
            }
        }
        return () => {
            if (presenMode == true) {
                var msg = {
                    type: 'exit',
                    uid: myUuidRef.current,
                    place: '10',
                }
                console.log('>>>>>>>> SEND: ShowDown/DISCONN :')
                socketRef.current.send(JSON.stringify(msg))
                setMasterMode(false)
                socketRef.current.close()
            }
        }
    }, []) // load時に ws を open する． wakeup 時は要検討

    useEffect(() => {
        if (masterMode == true) {
            var msg = {
                type: 'updaterule',
                rules: checkedRules,
            }
            console.log('>>>>>>>> SEND: update rule: ')
            socketRef.current.send(JSON.stringify(msg))
        }
    }, [checkedRules])

    useEffect(() => {
        if (origData !== undefined) {
            var { sc, teamSc, sumSc, sumTeamSc } = calcPoint(
                playMode,
                origData,
                parData,
                scores,
                sumScores,
                teamScores,
                sumTeamScores,
                pnum,
                members,
                teamMemberOrder,
                checkedRules
            )

            setScores(sc)
            setTeamScores(teamSc)
            setSumScores(sumSc)
            setSumTeamScores(sumTeamSc)
            //
            setUpdateVal((updateVal) => ++updateVal) // Force Rendering Update
        }
    }, [checkedRules])

    //
    // Style Definitions
    //
    const colorTeamA = 'red.500'
    const colorTeamB = 'blue.600'
    //const colorTeamC = 'pink.400'
    //const colorTeamD = 'yellow.600'
    const level3 = {
        color: 'white',
        bg: 'green.800',
        pos: 'sticky',
        zIndex: '5',
        textAlign: 'center',
        lineHeight: '110%',
        px: '2px',
        py: '5',
        fontSize: 'md',
    }
    const styleName = {
        bg: 'green.100',
        pos: 'sticky',
        zIndex: '3',
        top: '0',
        textAlign: 'center',
        lineHeight: '110%',
        p: '2px',
        fontSize: 'xl',
    }
    const styleHole = {
        color: 'white',
        bg: 'green.600',
        pos: 'sticky',
        zIndex: '4',
        left: '0',
        textAlign: 'center',
        lineHeight: '105%',
        px: '5px',
        py: '12px',
        fontSize: '2xl',
    }

    const stylePointDt = {
        color: 'gray.500',
        bg: 'yellow.100',
        pos: 'sticky',
        textAlign: 'center',
        p: '2px',
        fontSize: '2xl',
    }
    const stylePointSubTtl = {
        color: 'white',
        bg: 'green.400',
        pos: 'sticky',
        textAlign: 'center',
        p: '2px',
        fontSize: '2xl',
    }
    const styleDt = {
        color: 'gray.500',
        bg: 'white',
        textAlign: 'center',
        zIndex: '2',
        p: '2px',
        fontSize: '2xl',
    }
    const styleDtGray = { bg: 'gray.300', zIndex: '2', p: '2px', fontSize: 'xl' }
    const styleResult1 = {
        color: 'white',
        bg: 'green.500',
        pos: 'sticky',
        zIndex: '3',
        top: '0',
        textAlign: 'center',
        lineHeight: '110%',
        p: '2px',
        fontSize: '2xl',
    }
    const styleResult2 = {
        color: 'white',
        bg: 'green.600',
        pos: 'sticky',
        zIndex: '3',
        textAlign: 'center',
        p: '10px',
        fontSize: '2xl',
    }
    const styleTotalTeam = {
        color: 'white',
        bg: 'green.600',
        pos: 'sticky',
        zIndex: '3',
        textAlign: 'center',
        p: '2',
        fontSize: '2xl',
    }
    const styleTotal = {
        color: 'white',
        bg: 'green.600',
        pos: 'sticky',
        zIndex: '3',
        textAlign: 'center',
        p: '4',
        fontSize: '4xl',
    }

    function convVirtical(word) {
        let virtWord

        virtWord = word.slice(0, 1)
        if (word.length > 1) {
            for (let n = 1; n < 2; n++) {
                // 高さ制限のため名前は2文字でカット
                virtWord = virtWord + '<br />' + word.slice(n, n + 1)
            }
        }
        return { __html: virtWord }
    }

    const submitResult = async (e) => {
        // 勝敗の結果とチームポイントを DB に登録する
        const response = await fetch(API_ORIGIN + '/api/round/' + rid, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        const res = await response.json()
        console.log(
            '***** SubmitResult: get DB ',
            res['id'],
            res['date'],
            res['course']['id'],
            res['s_name'],
            res['team_win'],
            res['team_pt_a'],
            res['team_pt_b'],
            res['team_pt_c'],
            res['team_pt_d']
        )

        let teamWin = ''
        let ttlA = 0
        let ttlB = 0
        let ttlC = 0
        let ttlD = 0

        if (playMode === config.MODE4 && pnum >= 2) {
            // 4人/チームで2組
            ttlA = sumTeamScores[0][2].TeamTTL
            ttlB = sumTeamScores[1][2].TeamTTL
        } else {
            ttlA = sumTeamScores[0][2].TTL
            ttlB = sumTeamScores[1][2].TTL
            ttlC = sumTeamScores[2][2].TTL
            ttlD = sumTeamScores[3][2].TTL > 0 ? sumTeamScores[3][2].TTL : 0
        }
        // team_win の算出
        const ttl = [ttlA, ttlB, ttlC, ttlD]
        let max = Math.max(...ttl)
        for (let i = 0; i < pnum * 2; i++) {
            if (ttl[i] == max) {
                teamWin += teamName[i]
            }
        }
        // update data の準備
        let data = {
            date: res['date'],
            course_id: res['course']['id'],
            team_win: teamWin,
            team_pt_a: ttlA,
            team_pt_b: ttlB,
            team_pt_c: ttlC,
            team_pt_d: ttlD,
            s_name: res['s_name'],
            desc: res['desc'],
            res: res['res'],
        }
        console.log('***** SubmitResult: put DB ', rid, data)
        // update 実行
        fetch(API_ORIGIN + '/api/round/' + rid, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        })
        return
    }

    return (
        <>
            <Flex>
                <Table variant="simple" colorScheme="facebook" size="sm" width="90%">
                    <Thead>
                        <Tr textAlign="center">
                            <Th {...level3} left="0" top="0">
                                <DrawerDispQuickSettings
                                    checkedRules={checkedRules}
                                    setCheckedRules={setCheckedRules}
                                    masterMode={masterMode}
                                    setMasterMode={setMasterMode}
                                />
                            </Th>

                            {teamMemberOrder.map((i, n) => {
                                if (members[i].name != '')
                                    return (
                                        <>
                                            <Th {...styleName} left="0" h="80px">
                                                <div
                                                    dangerouslySetInnerHTML={convVirtical(
                                                        members[i].name
                                                    )}
                                                />
                                            </Th>
                                            {n % 2 == 1 && (
                                                <>
                                                    {playMode === config.MODE4 ? (
                                                        teamName[((n - 1) / 2) % 2] == 'A' ? (
                                                            <Th
                                                                key={'a' + n}
                                                                {...styleResult1}
                                                                bg={colorTeamA}
                                                                left="0"
                                                            >
                                                                {teamName[((n - 1) / 2) % 2]}
                                                            </Th>
                                                        ) : (
                                                            teamName[((n - 1) / 2) % 2] == 'B' && (
                                                                <Th
                                                                    key={'b' + n}
                                                                    {...styleResult1}
                                                                    bg={colorTeamB}
                                                                    left="0"
                                                                >
                                                                    {teamName[((n - 1) / 2) % 2]}
                                                                </Th>
                                                            )
                                                        )
                                                    ) : (
                                                        <Th {...styleResult1} left="0">
                                                            {teamName[(n - 1) / 2]}
                                                        </Th>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {checkedRules.PoP && (
                            <Tr>
                                <Th {...styleHole}>PoP</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'po0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].PoP != 0
                                                        ? sumScores[i][2].PoP
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'po1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].PoP != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].PoP
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.BiP && (
                            <Tr>
                                <Th {...styleHole}>BKiP</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'bi0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].BiP != 0
                                                        ? sumScores[i][2].BiP
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'bi1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].BiP != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].BiP
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.BDy && (
                            <Tr>
                                <Th {...styleHole}>BDy</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'bd0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].BDy != 0
                                                        ? sumScores[i][2].BDy
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'bd1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].BDy != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].BDy
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.DPt && (
                            <Tr>
                                <Th {...styleHole}>DPt</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'dp0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].DPt != 0
                                                        ? sumScores[i][2].DPt
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'dp1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].DPt != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].DPt
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.NPt && (
                            <Tr>
                                <Th {...styleHole}>NPt</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'np0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].NPt != 0
                                                        ? sumScores[i][2].NPt
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'np1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].NPt != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].NPt
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.On1 && (
                            <Tr>
                                <Th {...styleHole}>On1</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'on0' + n} {...stylePointDt} left="0">
                                                    {sumScores[i][2].On1P != 0
                                                        ? sumScores[i][2].On1P
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'on1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].On1P != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].On1P
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.OLt && (
                            <Tr>
                                <Th {...styleHole}>OLt</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'ol0' + n} {...styleDt} left="0">
                                                    {sumScores[i][2].OLt != 0
                                                        ? sumScores[i][2].OLt
                                                        : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'ol1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].OLM != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].OLM
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.HxH && (
                            <Tr>
                                <Th {...styleHole}>HxH</Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td key={'hh00' + n} {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'hh01' + n} {...stylePointSubTtl} left="0">
                                                    {sumTSc[2].HxH}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.HxHH && (
                            <Tr>
                                <Th {...styleHole} fontSize="xl">
                                    HxH1
                                </Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td key={'hh10' + n} {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'hh11' + n} {...stylePointSubTtl} left="0">
                                                    {sumTSc[2].HxHH1}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.HxHH && (
                            <Tr>
                                <Th {...styleHole} fontSize="xl">
                                    HxH2
                                </Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td key={'hh20' + n} {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'hh21' + n} {...stylePointSubTtl} left="0">
                                                    {sumTSc[2].HxHH2}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.St6 && (
                            <Tr>
                                <Th {...styleHole}>St6</Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'s0' + n} {...stylePointSubTtl}>
                                                    {sumTSc[2].St6 != 0 ? sumTSc[2].St6 : '-'}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.Pt6 && (
                            <Tr>
                                <Th {...styleHole}>Pt6</Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'pt0' + n} {...stylePointSubTtl}>
                                                    {sumTSc[2].Pt6 != 0 ? sumTSc[2].Pt6 : '-'}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.Ho3 && (
                            <Tr>
                                <Th {...styleHole}>Ho3</Th>
                                {sumTeamScores.map((sumTSc, n) => {
                                    if (members[n].name != '')
                                        return (
                                            <>
                                                <Td key={'ho0' + n} {...styleDtGray}></Td>
                                                <Td {...styleDtGray}></Td>
                                                <Th key={'ho1' + n} {...stylePointSubTtl} left="0">
                                                    {sumTSc[2].Ho3}
                                                </Th>
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.NetT && (
                            <Tr>
                                <Th {...styleHole} fontSize="xl">
                                    <Tooltip label="Net Team Top">NetT</Tooltip>
                                </Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th
                                                    key={'nt0' + n}
                                                    {...styleDt}
                                                    left="0"
                                                    fontSize={
                                                        sumScores[i][2].odds >= 100 ? 'xl' : '2xl'
                                                    }
                                                >
                                                    {sumScores[i][2].odds}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <>
                                                        <Tooltip
                                                            label={
                                                                sumTeamScores[(n + 1) / 2 - 1][2]
                                                                    .ODDS + 'p'
                                                            }
                                                        >
                                                            <Th
                                                                key={'nt1' + n}
                                                                {...stylePointSubTtl}
                                                                fontSize="xl"
                                                                bg={
                                                                    sumTeamScores[
                                                                        (n + 1) / 2 - 1
                                                                    ][2].ODDS > 0
                                                                        ? 'yellow.500'
                                                                        : 'green.400'
                                                                }
                                                            >
                                                                {
                                                                    sumTeamScores[
                                                                        (n + 1) / 2 - 1
                                                                    ][2].SpSO
                                                                }
                                                            </Th>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {(checkedRules.GrsT ||
                            checkedRules.NetT ||
                            checkedRules.BrkR ||
                            checkedRules.Rslt) && (
                            <Tr>
                                <Th
                                    {...styleHole}
                                    fontSize="xl"
                                    bg={checkedRules.GrsT ? 'green.600' : 'gray.400'}
                                >
                                    <Tooltip label="Gross Team">
                                        {checkedRules.GrsT ? 'GrsT' : 'Grst'}
                                    </Tooltip>
                                </Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th
                                                    key={'g0' + n}
                                                    {...styleDt}
                                                    left="0"
                                                    fontSize={
                                                        sumScores[i][2].stroke >= 100 ? 'xl' : '2xl'
                                                    }
                                                >
                                                    {sumScores[i][2].stroke}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <>
                                                        <Tooltip
                                                            label={
                                                                checkedRules.GrsT
                                                                    ? sumTeamScores[
                                                                          (n + 1) / 2 - 1
                                                                      ][2].GrsT + 'p'
                                                                    : ''
                                                            }
                                                        >
                                                            <Th
                                                                key={'g1' + n}
                                                                {...stylePointSubTtl}
                                                                fontSize="xl"
                                                                bg={
                                                                    checkedRules.GrsT &&
                                                                    sumTeamScores[
                                                                        (n + 1) / 2 - 1
                                                                    ][2].GrsT > 0
                                                                        ? 'yellow.500'
                                                                        : 'green.400'
                                                                }
                                                            >
                                                                {
                                                                    sumTeamScores[
                                                                        (n + 1) / 2 - 1
                                                                    ][2].SpS
                                                                }
                                                            </Th>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.Rslt && (
                            <Tr>
                                <Th {...styleHole} bg="gray.400" fontSize="xl">
                                    <Tooltip label="Team Putt">
                                        {checkedRules.Rslt ? 'Putt' : '(Putt)'}
                                    </Tooltip>
                                </Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th
                                                    key={'p0' + n}
                                                    {...styleDt}
                                                    left="0"
                                                    fontSize="2xl"
                                                >
                                                    {sumScores[i][2].putt}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <>
                                                        <Th
                                                            key={'p1' + n}
                                                            {...stylePointSubTtl}
                                                            fontSize="xl"
                                                            bg="green.400"
                                                        >
                                                            {sumTeamScores[(n + 1) / 2 - 1][2].PpP}
                                                        </Th>
                                                    </>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.BrkR && (
                            <Tr>
                                <Tooltip label="Brk Psl Rec">
                                    <Th {...styleHole} fontSize="xl">
                                        BrkR
                                    </Th>
                                </Tooltip>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Tooltip label={sumScores[i][2].BrkR + 'p'}>
                                                    <Th
                                                        key={'br0' + n}
                                                        {...styleDt}
                                                        left="0"
                                                        fontSize={
                                                            members[i].pbest >= 100 ? 'xl' : '2xl'
                                                        }
                                                        bg={
                                                            sumScores[i][2].BrkR > 0
                                                                ? 'yellow.500'
                                                                : 'yellow.100'
                                                        }
                                                    >
                                                        {members[i].pbest}
                                                    </Th>
                                                </Tooltip>
                                                {n % 2 == 1 && (
                                                    <Th key={'brkr1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].BrkR != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].BrkR
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                        {checkedRules.AL && (
                            <Tr>
                                <Th {...styleHole}>AL</Th>
                                {teamMemberOrder.map((i, n) => {
                                    if (members[i].name != '')
                                        return (
                                            <>
                                                <Th key={'al0' + n} {...stylePointDt} left="0">
                                                    {members[i].al != 0 ? members[i].al : '-'}
                                                </Th>
                                                {n % 2 == 1 && (
                                                    <Th key={'al1' + n} {...stylePointSubTtl}>
                                                        {sumTeamScores[(n + 1) / 2 - 1][2].AL != 0
                                                            ? sumTeamScores[(n + 1) / 2 - 1][2].AL
                                                            : '-'}
                                                    </Th>
                                                )}
                                            </>
                                        )
                                })}
                            </Tr>
                        )}
                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th {...styleHole}>TTL</Th>
                            {teamMemberOrder.map((i, n) => {
                                if (members[i].name != '')
                                    return (
                                        <>
                                            <Th {...styleResult1} left="0">
                                                {sumScores[i][2].TTL}
                                            </Th>
                                            {n % 2 == 1 && (
                                                <Th {...styleResult2} left="0">
                                                    {sumTeamScores[(n + 1) / 2 - 1][2].TTL}
                                                </Th>
                                            )}
                                        </>
                                    )
                            })}
                        </Tr>
                    </Tfoot>
                </Table>
                {/*
                        Total Table
                */}
                <Flex direction="column">
                    {playMode === config.MODE4 && pnum >= 2 && (
                        <Table variant="simple" colorScheme="facebook" size="lg" mx="2" w="30">
                            <Thead>
                                <Tr>
                                    <Th {...level3}>Total</Th>
                                </Tr>
                                {sumTeamScores.map((ttl, n) => {
                                    if (n < 2) {
                                        return (
                                            <>
                                                <Tr>
                                                    {teamName[n] == 'A' ? (
                                                        <Th {...styleTotalTeam} bg={colorTeamA}>
                                                            {teamName[n]}
                                                        </Th>
                                                    ) : teamName[n] == 'B' ? (
                                                        <Th {...styleTotalTeam} bg={colorTeamB}>
                                                            {teamName[n]}
                                                        </Th>
                                                    ) : (
                                                        <Th {...styleTotalTeam}>{teamName[n]}</Th>
                                                    )}
                                                </Tr>
                                                <Tr>
                                                    <Th {...styleTotal} bg="green.900">
                                                        {ttl[2].TeamTTL}
                                                    </Th>
                                                </Tr>
                                            </>
                                        )
                                    }
                                })}
                            </Thead>
                        </Table>
                    )}
                    <Spacer />
                    <Button m="4" colorScheme="teal" onClick={() => window.history.back()}>
                        Back
                    </Button>
                    {masterMode && (
                        <Button m="4" colorScheme="yellow" onClick={() => submitResult()}>
                            Submit Result
                        </Button>
                    )}

                    <Spacer />
                </Flex>
            </Flex>
        </>
    )
}
