/*

Regulation.js :

Mar.18,2024 : St6 条件変更 SpS は Team Stroke -> Team St_delta
Feb.21,2024 : 自己ベスト更新を追加
Dec.13,2023 : On1 3on = 2p に昇格
Oct.12,2023 : Back Btn 追加
Nov.13,2021 : Regulation ページ: 新規作成

*/
import React from 'react'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import { Stack, Button, Flex, Spacer } from '@chakra-ui/react'

const markdown = `
### Regulation

#### 変更履歴

- Jun.17,2024 : 味方Pair では NP 消ゴムをしない
- Mar.18,2024 : St6 条件変更 SpS は Team Stroke -> Team St_delta
- Feb.21,2024 : 自己ベスト更新を 3p, タイで 1p を追加
- Dec.14,2023 : 1-On 3回を 1p -> 2p に昇格
                Team Net Total を Default に変更
- Nov.11,2022 : HxH Half計, GrsT, BKiP を Default から除外
- Jul,21,2022 : Team Net を Default から除外
- Nov.13,2021 : Regulation ページ: 新規作成

##### 各Hole 個人

- パーオンパー(1p)
- バンカーインパー(1p) --> (default rule外)
- バーディ(2p):イーグル(3p)
- ドラコンポイント(default: パー締り)(2p x 14H) : (option: 縛りなし | ボギー縛り)
- ニアピンポイント(default: パー縛り & 消ゴム)(2p x4H+) : (option: 縛りなし | 消ゴムなし)

##### 各Hole 個人 in Party

- 3H以上連続 Honer獲得(1p):(各Hole の単独首位を3回連続で代用した:現状はフラグを示すのみで自動集計外)

##### Through 個人

- One Onポイント(4Hole-On: 3p, 3Hole-On: 2p, 2Hole-On: 1p, No-On: -1p)
- 自己ベスト更新(昨年度+今年度の自己ベストを更新した)(タイ:1p,更新:3p)

##### 各Hole Team

- Hole x Hole(掛算)(1p x 18Hole)
- Rule 6 (putt): チーム計 6以上 (-1p)
- Rule 6 (stroke - par): チーム計 6以上(-1p)

##### Half or Through Team対抗

- オリンピック(ダボ縛り)(ペア計-最下位ペア計) x 0.5p
- Hole x Holeハーフ合計(3p x2H) --> (default rule外)
- チームNET (1位:3p) --> (default)
- チームGross (1位:3p) --> (default rule外)
- 朝アル(1p/1杯)

#### 以下は未実装

- 各Hole個人 Stroke差(3打: 1p, 4打以上:2p)
- 各Hole個人 Putt差(3打: 1p, 4打以上:2p)
- チームハンデ戦(Stroke)(3p)(チームNETと同じ？)
- チームハンデ戦(Putt)(3p)

#### チーム構成

対応するチーム構成は以下

- 4人/チーム x 2 構成
- 2人/チーム x 3 or 4 構成
`

export default function Regulation(props) {
    return (
        <Flex>
            <Stack spacing={3} m={5}>
                <ReactMarkdown
                    components={ChakraUIRenderer()}
                    children={markdown}
                    escapeHtml={false}
                />
            </Stack>
            <Spacer />
            <Button m="14" p="5" w="30" colorScheme="teal" onClick={() => window.history.back()}>
                Back
            </Button>
        </Flex>
    )
}
