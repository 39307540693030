/*
    Box Leader Board

    Mar.18,2024 : plusMinus の時は std で sort しなければいけない
    Jul.19,2023 : plusMinus 対応
    Nov.13,2022 : 前半しか score がないと,後半が undefined で err になるの対応
    Nov.11,2022 : half TTL 表示追加. st_delta は削除
    Dec.06,2021 : par 以上, medal 有りを msg 表示追加
    Dec.02,2021 : 環境変数を config から .env に変更
    May.24,2021 : Button size を上位から props でもらうとした
    Apr.15,2021 : St と St Delta を合体して，大きな文字に変更
    Apr.14,2021 : rid を Context から props.rid に変更

*/
import React, { useState, useEffect } from 'react'
import {
    Box,
    Button,
    Flex,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
    Spinner,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react'

export default function BoxLB(props) {
    const API_ORIGIN = process.env.REACT_APP_BESVR_URI_ORIGIN

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    const [resultOrder, setResultOrder] = useState([])
    const [resultHalf, setResultHalf] = useState([])
    const [halfList, setHalfList] = useState([])
    const [holeEnd, setHoleEnd] = useState([])
    const [msgs, setMsgs] = useState([])
    const [isLoadingLB, setIsLoadingLB] = useState(false)

    const fetchThruScore = async () => {
        setIsLoadingLB(true)

        const response = await fetch(API_ORIGIN + '/api/point/leaders/' + props.rid)
        const [odata, hdata, hend, half, mdata] = await response.json()
        console.log('THRU Score :', odata, hdata, hend, half, mdata)

        setResultOrder(odata)
        setResultHalf(hdata)
        setHoleEnd(hend)
        setHalfList(half)
        setMsgs(mdata)
        setIsLoadingLB(false)
    }

    function handleOnOpen(e) {
        onOpen()
        fetchThruScore()
    }

    function convStDelta(data) {
        var res
        switch (data) {
            case -2:
                res = 'イーグル'
                break
            case -1:
                res = 'バーディ'
                break
            case 0:
                res = 'パー'
                break
            case 1:
                res = 'ボギー'
                break
            case 2:
                res = 'ダボ'
                break
            case 3:
                res = 'トリ'
                break
            default:
                res = '+' + data
                break
        }
        return res
    }
    function convOL(data) {
        var res
        switch (data) {
            case 1:
                res = '鉄'
                break
            case 2:
                res = '銅'
                break
            case 3:
                res = '銀'
                break
            case 4:
                res = '金'
                break
            case 5:
                res = 'ダイヤモンド'
                break
            default:
                res = ''
                break
        }
        return res
    }
    //
    // Plus Minus Function
    //
    const [plusMinusLB, setPlusMinusLB] = useState(false)

    function BoxPlusMinus(props) {
        return (
            <Button
                color="gray.800"
                bg="gray.300"
                fontSize={20}
                fontWeight="bold"
                p={0}
                onClick={() => setPlusMinusLB((prev_state) => !prev_state)}
            >
                ±
            </Button>
        )
    }

    //const styleTop = {  textAlign: "center" }
    const styleName = { textAlign: 'center', fontSize: '24', px: '0', mx: '0' }
    const styleTop = {
        color: 'white',
        bg: 'green.600',
        fontSize: 'lg',
        fontWeight: 'bold',
        textAlign: 'center',
    }
    //const styleName = { color: "white", bg: "green.500", textAlign: "center" }
    const styleDt = {
        textAlign: 'center',
        fontSize: '24',
        lineHeight: '1.05',
        px: '0',
        py: '1',
        m: '0',
    }

    console.log('LB: SUM: ', resultOrder)
    // 破壊的な関数
    if (plusMinusLB) {
        resultOrder.sort((a, b) => (a.total_st_delta > b.total_st_delta ? 1 : -1))
    } else {
        resultOrder.sort((a, b) => (a.total_stroke > b.total_stroke ? 1 : -1))
    }

    return (
        <>
            {isLoadingLB ? (
                <Spinner />
            ) : (
                <>
                    <Button {...props} colorScheme="yellow" ref={btnRef} onClick={handleOnOpen}>
                        LB
                    </Button>
                    <Drawer
                        isOpen={isOpen}
                        placement="top"
                        onClose={onClose}
                        finalFocusRef={btnRef}
                    >
                        <DrawerOverlay>
                            <DrawerContent>
                                <DrawerCloseButton />
                                <DrawerHeader>
                                    Leader Board
                                    <BoxPlusMinus flag={plusMinusLB} />
                                </DrawerHeader>

                                <DrawerBody>
                                    <Table size="sm" colorScheme="facebook" variant="striped">
                                        <Thead>
                                            <Tr>
                                                <Th {...styleTop}>name</Th>
                                                <Th {...styleTop}>St</Th>
                                                <Th {...styleTop}>Pt</Th>
                                                <Th {...styleTop}>THRU</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {resultOrder.map((dt, i) => {
                                                return (
                                                    <Tr key={i}>
                                                        <Td {...styleName}>{dt.p_name}</Td>
                                                        <Td {...styleDt}>
                                                            {plusMinusLB
                                                                ? (dt.total_st_delta > 0 && '+') +
                                                                  dt.total_st_delta +
                                                                  ' (' +
                                                                  resultHalf.find(
                                                                      (v) =>
                                                                          v.p_name === dt.p_name &&
                                                                          v.half_name == halfList[0]
                                                                  ).sd +
                                                                  '/' +
                                                                  (resultHalf.find(
                                                                      (v) =>
                                                                          v.p_name === dt.p_name &&
                                                                          v.half_name == halfList[1]
                                                                  )
                                                                      ? resultHalf.find(
                                                                            (v) =>
                                                                                v.p_name ===
                                                                                    dt.p_name &&
                                                                                v.half_name ==
                                                                                    halfList[1]
                                                                        ).sd
                                                                      : '-') +
                                                                  ')'
                                                                : dt.total_stroke +
                                                                  ' (' +
                                                                  resultHalf.find(
                                                                      (v) =>
                                                                          v.p_name === dt.p_name &&
                                                                          v.half_name == halfList[0]
                                                                  ).st +
                                                                  '/' +
                                                                  (resultHalf.find(
                                                                      (v) =>
                                                                          v.p_name === dt.p_name &&
                                                                          v.half_name == halfList[1]
                                                                  )
                                                                      ? resultHalf.find(
                                                                            (v) =>
                                                                                v.p_name ===
                                                                                    dt.p_name &&
                                                                                v.half_name ==
                                                                                    halfList[1]
                                                                        ).st
                                                                      : '-') +
                                                                  ')'}
                                                        </Td>
                                                        <Td {...styleDt}>
                                                            {dt.total_putt} (
                                                            {
                                                                resultHalf.find(
                                                                    (v) =>
                                                                        v.p_name === dt.p_name &&
                                                                        v.half_name == halfList[0]
                                                                ).pt
                                                            }
                                                            /
                                                            {resultHalf.find(
                                                                (v) =>
                                                                    v.p_name === dt.p_name &&
                                                                    v.half_name == halfList[1]
                                                            )
                                                                ? resultHalf.find(
                                                                      (v) =>
                                                                          v.p_name === dt.p_name &&
                                                                          v.half_name == halfList[1]
                                                                  ).pt
                                                                : '-'}
                                                            )
                                                        </Td>
                                                        <Td {...styleDt}>
                                                            {
                                                                holeEnd.find(
                                                                    (v) => v.p_name === dt.p_name
                                                                ).hole_full_name
                                                            }
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                    <Box>
                                        {msgs.map((data, i) => {
                                            return (
                                                <Flex>
                                                    <Text>
                                                        {data.half_name} {data.hole_num}:{' '}
                                                        {data.p_name} さん :{' '}
                                                    </Text>
                                                    <Text color="red.600">
                                                        {convStDelta(data.st_delta)}{' '}
                                                        {data.ol > 0 ? ' / ' + convOL(data.ol) : ''}
                                                    </Text>
                                                    <Text> です．</Text>
                                                </Flex>
                                            )
                                        })}
                                    </Box>
                                </DrawerBody>
                            </DrawerContent>
                        </DrawerOverlay>
                    </Drawer>
                </>
            )}
        </>
    )
}
