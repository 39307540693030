/*
    Score Entry

    Feb.27,2024 : TeeShot は BoxScore に移動
    Jul.18,2023 : plusMinusMode 対応
    Dec.06,2021 : Nav fixed 対応
    Apr.12,2021 : PointBoard を TopBody から，こちらの Bottom に移動,
                  Leader Board もエントリを追加した

*/
import React, { useContext, useEffect } from 'react'
import { Box, Button, Flex, Stack, Spacer, HStack, Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { PartyContext } from '../pages/Score'

import TopBody from './TopBody'
import MidBody from './MidBody'
//import TeeShot from './TeeShot'
import BoxPt from './BoxPt'
import BoxMemo from './BoxMemo'
import DrawExtSite from './DrawExtSite'

export default function ScoreBoard(props) {
    const { course, inputUid, plusMinusMode, setPlusMinusMode, partyData, parties, colorZone1 } =
        useContext(PartyContext)

    const point_path = {
        pathname: '/Point/' + partyData.t_round,
        // t_round = round_id in this case
        state: {
            course: course,
            parties: parties,
        },
    }

    return (
        <Stack pos="relative" top="32px" spacing={0}>
            <TopBody w="100%" />
            <MidBody w="100%" />
            <Box w="100%" borderColor="green.200" borderWidth="2px">
                <Stack>
                    {/*
                    <TeeShot w="100%" uid={inputUid} zone={colorZone1} />
                    */}
                    <HStack spacing={1}>
                        <Center fontSize={18} bg="gray.100" minW={1 / 6}>
                            OB
                        </Center>
                        <BoxPt cat="ob" uid={inputUid} zone={colorZone1} />
                        <Center fontSize={18} bg="gray.100" w={1 / 6}>
                            Banker
                        </Center>
                        <BoxPt cat="banker" uid={inputUid} zone={colorZone1} />
                        <Center fontSize={18} bg="gray.100" w={1 / 6}>
                            Penalty
                        </Center>
                        <BoxPt cat="penalty" uid={inputUid} zone={colorZone1} />
                    </HStack>
                    <BoxMemo uid={inputUid} />
                    {/*
                    <DrawerMemo uid={inputUid} zone={colorZone1} />
                    */}
                    {/* Leaders Board / Point Board */}
                    <Flex>
                        <Button
                            color="gray.800"
                            bg="gray.300"
                            fontSize={30}
                            fontWeight="bold"
                            p={0}
                            onClick={() => setPlusMinusMode((prev_state) => !prev_state)}
                        >
                            ±
                        </Button>
                        <Spacer />
                        <DrawExtSite />
                        <Link to={point_path}>
                            <Button colorScheme="yellow">Pt</Button>
                        </Link>
                    </Flex>
                </Stack>
            </Box>
        </Stack>
    )
}
