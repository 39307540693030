/*
Box Score

ひとり分の Score の表示(ttl, st, pt, ol, dpnp)

Oct.01,2024 : OE/PE/BK を Drawer で入力可とした．4人のデータ入力ができるようになった．
Feb.27,2024 : DPNP, TeeShot を全面変更．TeeShot で DPNP を兼用した
Jul.15,2023 : PT -> Pt fontsize 変更
Apr.19,2021 : 3人/Pty など名前が無い時の対応
Apr.15,2021 : st_delta +/- 表記に変更

*/
import React, { useContext } from 'react'
import {
    Box,
    Button,
    Center,
    Flex,
    IconButton,
    HStack,
    Stack,
    Text,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
    DrawerContent,
    DrawerCloseButton,
    Input,
    useNumberInput,
} from '@chakra-ui/react'
import { StarIcon, AddIcon } from '@chakra-ui/icons'

import config from '../pages/const'
import BoxTotal from './BoxTotal'
import BoxDPNP from './BoxDPNP'
import BoxOL from './BoxOL'
import BoxSt from './BoxSt'
import BoxPt from './BoxPt'
import TeeShot from './TeeShot'

import { PartyContext } from '../pages/Score'

/*
function CntBtn(props) {
    const {
        scoreP1,
        setScoreP1,
        scoreP2,
        setScoreP2,
        scoreP3,
        setScoreP3,
        scoreP4,
        setScoreP4,
        colorZone3,
    } = useContext(PartyContext)

    const scorePx = eval('scoreP' + props.uid)
    const setScorePx = eval('setScoreP' + props.uid)

    const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
        step: 1,
        defaultValue: 0,
        min: 0,
        max: 6,
        precision: 0,
        onChange: console.log('IN ***** '),
    })

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const handleChange = (e) => {
        console.log('IN ***** ')
        if (props.cat === 'ob') {
            setScorePx({
                ...scorePx,
                ob: Number(e.target.value),
            })
        } else if (props.cat === 'banker') {
            setScorePx({
                ...scorePx,
                banker: Number(e.target.value),
            })
        } else if (props.cat === 'penalty') {
            setScorePx({
                ...scorePx,
                penalty: Number(e.target.value),
            })
        }
        console.log('Pt val = ', props.cat, e.target.value)
    }

    return (
        <HStack>
            <Center fontSize={18} bg="gray.100" minW={1 / 6}>
                {props.cat}
            </Center>
            <Input
                fontSize={46}
                fontWeight="bold"
                maxW={70}
                color="white"
                bg="green.500"
                onChange={handleChange}
                {...input}
            />
            <Button fontSize={30} fontWeight="bold" color="white" bg="green.500" {...inc}>
                ＋
            </Button>
            <Button fontSize={38} fontWeight="bold" color="white" bg="green.500" {...dec}>
                −
            </Button>
        </HStack>
    )
}
*/

function DrawerPE(props) {
    //
    // Drawer for OB/PE/BK
    //
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <>
            <Button textAlign="center" color="gray.700" onClick={onOpen}>
                + ext
            </Button>
            <Drawer
                size="md"
                isOpen={isOpen}
                placement="bottom"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>Extra Data</DrawerHeader>

                        <DrawerBody>
                            {/* <CntBtn cat="ob" uid={props.uid} zone={props.zone} fontSize={20} /> */}
                            <HStack spacing={1}>
                                <Center fontSize={18} bg="gray.100" minW={1 / 6}>
                                    OB
                                </Center>
                                <BoxPt cat="ob" uid={props.uid} zone={props.zone} fontSize={20} />
                            </HStack>
                            <HStack spacing={1}>
                                <Center fontSize={18} bg="gray.100" minW={1 / 6}>
                                    PE
                                </Center>
                                <BoxPt cat="penalty" uid={props.uid} zone={props.zone} />
                            </HStack>
                            <HStack spacing={1}>
                                <Center fontSize={18} bg="gray.100" minW={1 / 6}>
                                    BK
                                </Center>
                                <BoxPt cat="banker" uid={props.uid} zone={props.zone} />
                            </HStack>
                            <Center fontSize={18} minW={1 / 6}>
                                -
                            </Center>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    )
}
export default function BoxScore(props) {
    const {
        inputMode,
        inputUid,
        isOnLine,
        colorZone1,
        colorZone2,
        colorZone3,
        //curHole, partyData,
    } = useContext(PartyContext)

    // Set Name plate bg Color
    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2
    /*
    const totalNum = totalScore ? totalScore.find((v) => v.p_id == props.uid) : null
    //console.log("BoxScore: totalNum : ", totalScore, totalNum)
    const ttlStroke = totalNum ? totalNum.total_stroke : 0
    const ttlStrokeDelta = totalNum ? totalNum.total_st_delta : 0
    const ttlPutt = totalNum ? totalNum.total_putt : 0
    */
    //console.log("BoxScore: uid)
    //console.log("BoxScore: SDATA : ", sdata[0].stroke)
    //const data = eval("sdata" + props.uid)
    //console.log("BoxScore: SDATA : ", data)
    //console.log("BoxScore: isOnLine : ", props.id, isOnLine, isOnLine[props.id])
    return (
        <Flex bg="gray.100">
            <Stack spacing={1.5}>
                {/* Player Name */}
                <Box fontSize={26} textAlign="center" color="white" bg={myZone}>
                    {props.name.length === 0 ? '-' : props.name}
                    {inputMode === config.INDIVISUAL_MODE ? (
                        <StarIcon
                            color={isOnLine[props.id] === true ? 'yellow.300' : 'gray.600'}
                            w={3}
                        />
                    ) : (
                        ''
                    )}
                </Box>
                <BoxTotal uid={props.uid} zone={myZone} />
                {/* Stroke */}
                <BoxSt uid={props.uid} zone={myZone} />
                <HStack>
                    <Center fontSize={18} w="30%">
                        Pt
                    </Center>
                    {/* Putt */}
                    <BoxPt cat="putt" uid={props.uid} zone={myZone} w="70%" />
                </HStack>
                <HStack>
                    {/* DP */}
                    <BoxDPNP uid={props.uid} zone={myZone} w={1 / 2} />
                    {/* OL */}
                    <BoxOL uid={props.uid} zone={myZone} w={1 / 2} />
                </HStack>
                {/* TeeShot */}
                <TeeShot uid={props.uid} zone={myZone} w={1 / 2} />
                <DrawerPE uid={props.uid} zone={myZone} />
            </Stack>
        </Flex>
    )
}
