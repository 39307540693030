/*
Box Score

ひとり分の Score の表示(ttl, st, pt, ol, dpnp)

Feb.27,2024 : DPNP, TeeShot を全面変更．TeeShot で DPNP を兼用した
Jul.15,2023 : PT -> Pt fontsize 変更
Apr.19,2021 : 3人/Pty など名前が無い時の対応
Apr.15,2021 : st_delta +/- 表記に変更

*/
import React, { useContext } from 'react'
import { Box, Center, Flex, HStack, Stack } from '@chakra-ui/react'
import { StarIcon } from '@chakra-ui/icons'

import config from '../pages/const'
import BoxTotal from './BoxTotal'
import BoxDPNP from './BoxDPNP'
import BoxOL from './BoxOL'
import BoxSt from './BoxSt'
import BoxPt from './BoxPt'
import TeeShot from './TeeShot'

import { PartyContext } from '../pages/Score'

export default function BoxScore(props) {
    const {
        inputMode,
        inputUid,
        isOnLine,
        colorZone1,
        colorZone2,
        colorZone3,
        //curHole, partyData,
    } = useContext(PartyContext)

    // Set Name plate bg Color
    var myZone = colorZone3
    if (inputUid === props.uid) myZone = colorZone1
    else if (inputMode === config.VOLUNTEER_MODE) myZone = colorZone2
    /*
    const totalNum = totalScore ? totalScore.find((v) => v.p_id == props.uid) : null
    //console.log("BoxScore: totalNum : ", totalScore, totalNum)
    const ttlStroke = totalNum ? totalNum.total_stroke : 0
    const ttlStrokeDelta = totalNum ? totalNum.total_st_delta : 0
    const ttlPutt = totalNum ? totalNum.total_putt : 0
    */
    //console.log("BoxScore: uid)
    //console.log("BoxScore: SDATA : ", sdata[0].stroke)
    //const data = eval("sdata" + props.uid)
    //console.log("BoxScore: SDATA : ", data)
    //console.log("BoxScore: isOnLine : ", props.id, isOnLine, isOnLine[props.id])
    return (
        <Flex bg="gray.100">
            <Stack spacing={1.5}>
                {/* Player Name */}
                <Box fontSize={26} textAlign="center" color="white" bg={myZone}>
                    {props.name.length === 0 ? '-' : props.name}
                    {inputMode === config.INDIVISUAL_MODE ? (
                        <StarIcon
                            color={isOnLine[props.id] === true ? 'yellow.300' : 'gray.600'}
                            w={3}
                        />
                    ) : (
                        ''
                    )}
                </Box>
                <BoxTotal uid={props.uid} zone={myZone} />
                {/* Stroke */}
                <BoxSt uid={props.uid} zone={myZone} />
                <HStack>
                    <Center fontSize={18} w="30%">
                        Pt
                    </Center>
                    {/* Putt */}
                    <BoxPt cat="putt" uid={props.uid} zone={myZone} w="70%" />
                </HStack>
                <HStack>
                    {/* DP */}
                    <BoxDPNP uid={props.uid} zone={myZone} w={1 / 2} />
                    {/* OL */}
                    <BoxOL uid={props.uid} zone={myZone} w={1 / 2} />
                </HStack>
                {/* TeeShot */}
                <TeeShot uid={props.uid} zone={myZone} w={1 / 2} />
            </Stack>
        </Flex>
    )
}
