/*
   Memo Input
    props.uid に対応した scorePx に stroke を設定する．

    Input: PartyContext, uid
    Output: scorePx.memo

    Apr.16,2021 : onFocus は react でサポートなし
    Apr.15,2021 : iPhone 使用時，drawer 方式では, drawer を閉じたときに元の画面が崩れるので 
                  drawer 方式をやめた．
                  Textarea を row=1 とした．
*/
import React, { useRef, useContext } from "react";
import { Button, Input, HStack, } from "@chakra-ui/react"

import { PartyContext } from '../pages/Score'

export default function BoxMemo(props) {
    // 基本 Box
    const {
        scoreP1, setScoreP1, scoreP2, setScoreP2,
        scoreP3, setScoreP3, scoreP4, setScoreP4,
    } = useContext(PartyContext)

    const scorePx = eval("scoreP" + props.uid)
    const setScorePx = eval("setScoreP" + props.uid)

    const inputEl = useRef(null)
    const handleClick = () => {
        setScorePx({
            ...scorePx,
            memo: inputEl.current.value
        })
        console.log("BoxMemo val = ", inputEl.current.value)
    }
    const writeProtect = scorePx.lock

    return (
        <>
            <HStack>
                <Input ref={inputEl} type="text"
                    defaultValue={scorePx.memo}
                    isDisabled={writeProtect}
                    placeholder="これは自由メモ欄です max 128文字"
                />
            <Button onClick={handleClick}>OK</Button>
            </HStack>
        </>

    )
}
